
$(window).on('load', function() {
    let dropOrigin,
        selectableEl;

    $('.pallet').draggable({
        revert: 'invalid',
        start: function (ev, ui) {
            let from = $(ui.helper).parent().data('location');
            $(ui.helper).data('from', from);

            dropOrigin = $(ui.helper).parent();
        }
    });

    $('.slot').droppable({
        hoverClass: 'ui-state-highlight',
        drop: function (ev, ui) {
            let $this = $(this);
            let from = ui.draggable.data('from'),
                id = ui.draggable.data('pallet'),
                loc_barcode = $(this).data('location'),
                slot = $(this).find('.slot-label').text();

            if (from !== loc_barcode) {
                let message = `<p>Are you sure you want to move pallet <strong>${id}</strong> to <strong>${slot}</strong>?</p>`;
                let dialogModal = $('#dialog-message');
                dialogModal.html(message);
                dialogModal.dialog({
                    modal: true,
                    buttons: {
                        'Confirm Location': function (ev) {
                            dialogModal.dialog('destroy');
                            $.post('/m/update-location', {id, loc_barcode}, function (response) {
                                let notify = dialogModal.find('p').text(response.message).dialog({
                                    modal: true,
                                });

                                ui.draggable.detach().css({top: 0, left: 0}).appendTo($this);
                                setTimeout(function () {
                                    window.scrollTo(0, 0);
                                    notify.dialog('close');
                                }, 1000);

                                if ($(this).find('.selectable a').length) {
                                    selectableEl = $(this).find('.selectable').detach();
                                    dropOrigin.append(selectableEl.show());
                                }
                            });
                        },
                        'Cancel': function () {
                            dialogModal.dialog('destroy');
                            ui.draggable.css({top: 0, left: 0});
                        },
                    }
                });
            }
        },
        over: function (ev, ui) {
            $(this).find('.selectable').hide();
        },
        out: function (ev, ui) {
            $(this).find('.selectable').show();
        }
    });

    function confirm_dialog(data) {
        let {id, loc_barcode, slot} = data;
        let dialog = $('.confirm-modal').dialog({
            dialogClass: 'no-close',
            modal: true,
            resizable: false,
            draggable: false,
            open: function (ui) {
                let $this = $(ui.target),
                    $message = $this.find('.message-body');

                    $this.find('.buttons').show();

                let message = `Are you sure you want to putaway pallet <strong>${id}</strong> to <strong>${slot}</strong>?`;
                $message.html(message);

                $this.find('a.cancel').on('click', function (e) {
                    e.preventDefault();
                    dialog.dialog('close');
                });

                $this.find('a.confirm').on('click', function (e) {
                    e.preventDefault();

                    $.post('/m/update-location', data, function (response) {
                        $message.html(response.message);
                        $this.find('.buttons').hide();

                        setTimeout(fn => window.location = '/' + response.redirect, 2e3);
                    });
                });
            }
        });
    }

    $('.selectable a').on('click', function (e) {
        e.preventDefault();

        let $this = $(this).parent();
        let id = $this.data('pallet'),
            loc_barcode = $this.parents('.tile').data('location'),
            slot = $.trim($this.parents('.tile').find('.slot-label').text());

        confirm_dialog({ id, loc_barcode, slot });
        // return;

        // if (confirm(`Are you sure you want to putaway pallet ${id} to ${slot}?`)) {
        //     $.post('/m/update-location', {id, loc_barcode}, function (response) {
        //         alert(response.message);
        //         window.location = '/' + response.redirect;
        //     });
        // }
    });
});
