import PubNub from 'pubnub'

import 'jquery-form'
import 'jquery-validation';

import 'webpack-jquery-ui'
import 'webpack-jquery-ui/css'
import '@rwap/jquery-ui-touch-punch'

// css
import 'bulma'
import 'bulma-extensions'
import 'bulma-helpers'

import 'spin.js'
import 'ios-overlay'
import 'anypicker'

import './_location'
import './_digiform'

(($) => {
    $.whs = {};

    $.extend($.ui.dialog.prototype.options, {
        modal: true,
        resizable: false,
        draggable: false,
        width: 380,
        my: "center",
        at: "center",
        of: window
    });

    $(window).on('load', () => {

        $('.table.fade').each(function (index) {
            let $this = $(this);
            setTimeout(function () {
                $this.addClass('fade-in');
            }, index * 150);
        });
    });

    // prevent double clicking of submit button
    $(document).on('submit', 'form', function (e) {
        let $form = $(e.target);

        $('input[type=submit]', $form)
            .prop('disabled', true);
    });

    // Check for click events on the navbar burger icon
    $(document).on('click', '.navbar-burger', function () {

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");

    });

    window.whs = {};
    window.whs.message = $.whs.message = function (type, text, subtext) {
        let $div = $(document).find('.message');
        let $className = '';

        let $title = $div.find('#message_header_text');

        switch (type) {
            case 'success':
                $className = 'is-success';
                $title.text('Success');
                break;
            case 'error':
                $className = 'is-danger';
                $title.text('Error');
                break;
            case 'warning':
            case 'warn':
                $className = 'is-warning';
                $title.text('Warning');
                break;
            default:
                $className = 'is-info';
                $title.text('Info');
        }

        $div.removeClass('is-success is-danger is-hidden').addClass($className);
        $div.find('.message-content').empty().text(text);

        $('html, body').animate({
            scrollTop: 0
        }, 'slow');

        $(document).on('click', '.message-header .delete', function () {
            $div.addClass('is-hidden');
        });
    };

    $.whs.loader = (text) => {
        let $pageloader = $('.pageloader');
        $pageloader.find('.pageloader-text').text(text);
        $pageloader.addClass('is-active');

        $('body').addClass('no-scroll');

        return {
            hide() {
                $pageloader.removeClass('is-active');
            }
        };
    };

    $(document).on('click', '#upload-paperwork', showUploadDialog);

    function showUploadDialog(e) {
        e.preventDefault();
        let $this = $(e.target);
        console.log($this.data());

        $.get('/paperwork/dialog-upload', {
            id: $(this).data('order-id'),
            type: $this.data('type') || 'outward', source: 'scanner'
        }, function (html) {
            $('#show_content').dialog({
                open: function () {
                    $(this).html(html)
                }
            });
        });
    }

    let auto_submit_barcode = function (e) {
        let $el = $(e.target);

        if ( $.trim($el.val()) ) {
            $el.parents('form').trigger('submit');
        }
    };

    let throttled_event = _.debounce(auto_submit_barcode, 1e3);
    $(document).on('change input keyup', '#barcode', throttled_event);

    // incoming order page (desktop view)
    $(window).on('load', function () {
        $('#unloaded-by-lookup').autocomplete({
            source: '/ajax_inward/users',
            select: function (ev, ui) {
                $('#unloaded-by').val(ui.item.id);
                $('tbody td:nth-child(9)').text(ui.item.value);
                $('#putaway-form').trigger('submit');
            }
        });

        $('#putaway-form').ajaxForm({
            url: '/ajax_inward/save',
            success: function (resp) {
                $.whs.message(resp.type, resp.message);
                $('#putaway-form input[type=submit]').attr('disabled', false);
            },
        });
    });

    const pubnub = window.pubnub = new PubNub({
        subscribeKey: 'sub-c-8c82ad41-15b8-487a-94dd-eba0aa0e1137',
        publishKey: 'pub-c-a8be579a-ac63-4bff-8d38-f86ed005d5f4',
        userId: 'whs-' + Math.random(),
    });
})(jQuery);
